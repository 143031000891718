import React from "react"

import { motion } from "framer-motion"
import { IoMdClose as CloseIcon } from "react-icons/io"
import { useDatabaseObjectData } from "reactfire"

import { groupsDb } from "../../firebase"
import { RibbonSubTitle, RibbonTitle } from "../../logged-in/components"
import { ButtonLink, theme, toEm } from ".."

const Content: React.FC<{ campaignId: string; onClose(): void }> = ({
  campaignId,
  onClose,
  ...props
}) => {
  const campaign = useDatabaseObjectData<{
    title: string
    buttonText: string
    description: string
    link: string
  }>(groupsDb.ref(`/campaigns/${campaignId}`)).data

  if (!campaign.title) {
    return null
  }

  return (
    <motion.div
      initial={{ translateY: "100%" }}
      animate={{ translateY: 0 }}
      css={{ zIndex: 3, position: "fixed", bottom: 0, left: 0, right: 0 }}
      {...props}
    >
      <div
        css={{
          backgroundColor: theme.colors.blue,
          color: theme.colors.white,
          padding: "5em 3em",
        }}
      >
        <RibbonTitle>{campaign.title}</RibbonTitle>
        <RibbonSubTitle>{campaign.description}</RibbonSubTitle>
        <ButtonLink
          color={theme.colors.white}
          variant="filled"
          css={{ marginTop: 32 }}
          to={campaign.link}
        >
          {campaign.buttonText}
        </ButtonLink>
        <div
          aria-label="Close"
          onClick={onClose}
          css={{
            backgroundColor: theme.colors.white,
            cursor: "pointer",
            position: "absolute",
            right: "1em",
            top: "1em",
            padding: ".5em",
            borderRadius: toEm(12),
            lineHeight: 0,
            boxShadow: `0px ${toEm(3)} ${toEm(6)} rgba(0,0,0,0.16)`,
          }}
        >
          <CloseIcon size={"3em"} color={theme.colors.blue} />
        </div>
      </div>
    </motion.div>
  )
}

export const CampaignBanner: React.FC = ({ ...props }) => {
  const [showCampaignBanner, setShowCampaignBanner] = React.useState<
    null | string
  >(null)

  React.useEffect(() => {
    const res = location.href.match(/cgn=([^&]+)/)
    const campaignId = res && res[1]

    setShowCampaignBanner(campaignId)
  }, [])

  if (!showCampaignBanner) {
    return null
  }

  return (
    <React.Suspense fallback={<></>}>
      <Content
        campaignId={showCampaignBanner}
        onClose={() => {
          setShowCampaignBanner(null)
        }}
        {...props}
      />
    </React.Suspense>
  )
}
